<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <!-- header breadcrumb -->
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'onboarding' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item
              v-if="!verifiedStatus"
              :to="{ name: 'browse-conversions-new' }"
            >
              Conversions
            </b-breadcrumb-item>
            <b-breadcrumb-item
              v-if="verifiedStatus"
              :to="{ name: 'browse-conversions-verified' }"
            >
              Conversions
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{ name: 'view-conversion', params: { id: resourceId } }"
              :active="true"
            >
              View Conversion
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>

      <!-- header actions -->
      <b-col
        v-if="resource.id"
        md="12"
        class="text-right"
      >
        <b-button
          class="ml-1"
          variant="primary"
          @click="openFormRemapModal"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-text-top"
          /> Form Settings
        </b-button>

        <!-- return to previous page -->
        <b-button
          v-if="returnTo"
          class="ml-1"
          variant="outline-primary"
          :to="returnTo"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Return to Conversions
        </b-button>

        <!-- return to new conversions -->
        <b-button
          v-if="!verifiedStatus && !returnTo"
          class="ml-1"
          variant="outline-primary"
          :to="{name: 'browse-conversions-new'}"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Return to Conversions
        </b-button>

        <!-- return to verified conversions -->
        <b-button
          v-if="verifiedStatus && !returnTo"
          class="ml-1"
          variant="outline-primary"
          :to="{name: 'browse-conversions-verified'}"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Return to Conversions
        </b-button>
      </b-col>
    </b-row>

    <!-- no resource loaded -->
    <b-card
      v-if="!resource.id"
      class="mt-2"
    >
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        Conversion Details
      </b-card-title>
      <div>
        <div class="p-1 mb-1 border-bottom rounded">
          <p class="m-0">
            Conversion data not found.
          </p>
        </div>
      </div>
    </b-card>

    <!-- conversion status -->
    <b-card
      v-if="resource.id"
      class="mt-2"
    >
      <b-row>
        <b-col md="6">
          <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
            Conversion Status
          </b-card-title>
        </b-col>
        <b-col
          md="6"
          class="text-right"
        >
          <p>
            <strong>Created:</strong> {{ resource.conversion_time }}
            <br>
            <strong>Form Name:</strong> {{ remapFormData.form_name }}
          </p>
        </b-col>
      </b-row>

      <div class="text-center">
        <b-alert
          :variant="statusColor[resource.status]"
          :style="{ fontSize: '1.5em' }"
          show
        >
          <div class="alert-body">
            <span><strong>Status:</strong> {{ resource.status }}</span>
            <span
              v-for="(item, index) in resource.notes"
              :key="index"
              class="note"
            >
              {{ item }}
            </span>
          </div>
        </b-alert>

        <div id="actions">
          <div v-if="!resource.can_verify">
            <!-- TODO: conversion is on verification cooldown -->
            <span class="d-inline-block action-button btn-formsettings">
              <b-button
                variant="outline-warning"
                @click="handleItemCooldown(resource)"
              >
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                Not Ready
              </b-button>
            </span>
          </div>
          <div v-else-if="verifiedStatus || wonStatus">
            <b-button
              class="mx-1 mb-1"
              variant="danger"
              @click="handleItemApprove(resource.id, 'lost')"
            >
              <font-awesome-icon :icon="['fas', 'xmark']" />
              Closed Lost (Canceled)
            </b-button>

            <b-button
              class="mx-1 mb-1"
              variant="warning"
              @click="handleItemApprove(resource.id, 'pending')"
            >
              <font-awesome-icon :icon="['far', 'clock']" />
              Pending (In Progress)
            </b-button>

            <b-button
              class="mx-1 mb-1"
              variant="success"
              @click="handleItemApprove(resource.id, 'won')"
            >
              <font-awesome-icon :icon="['fas', 'check-double']" />
              Closed Won (Invoiced)
            </b-button>
          </div>
          <div v-else>
            <!-- TODO: only show Form Settings button if type F -->
            <b-button
              v-if="!formIsRemapped"
              v-b-modal.modal-remap
              class="mx-1 mb-1"
              variant="info"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              /> Form Settings
            </b-button>

            <b-button
              v-if="formIsRemapped"
              class="mx-1 mb-1"
              variant="success"
              @click="openApproveConversionForm()"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
              /> Approve / Verify
            </b-button>

            <b-button
              class="mx-1 mb-1"
              variant="danger"
              @click="rejectConversion(resource.id)"
            >
              <feather-icon
                icon="XCircleIcon"
                size="16"
              /> Decline / Reject
            </b-button>

            <b-button
              class="mx-1 mb-1"
              variant="warning"
              @click="spamConversion(resource.id)"
            >
              <feather-icon
                icon="AlertCircleIcon"
                size="16"
              /> Flag as Spam
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <!-- lead form details -->
    <b-card
      v-if="formIsRemapped && typeIsForm"
      class="mt-2"
    >
      <b-card-title class="pl-1 border-left-primary border-left-3 d-inline">
        Lead Form Details
      </b-card-title>

      <b-row class="mt-1">
        <b-col>
          <div class="lead-form-remap">
            <b-row>
              <b-col
                v-for="(item, keyName) in remapFormData.form_remapped_data"
                :key="keyName"
                md="6"
                class="p-1"
              >
                <h5>{{ keyName }}</h5>
                <p>{{ item }}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- utm details -->
    <!-- Object.keys(validUtmData).length > 0 -->
    <b-card
      v-if="validUtmData"
      class="mt-2"
    >
      <b-card-title class="pl-1 border-left-primary border-left-3 d-inline">
        Tracking Details
      </b-card-title>

      <b-row class="mt-1">
        <b-col>
          <div class="lead-form-remap">
            <b-row>
              <b-col
                v-for="(item, keyName) in validUtmData"
                :key="keyName"
                md="6"
                class="p-1"
              >
                <h5>{{ keyName }}</h5>
                <p>{{ item }}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- conversion history -->
    <b-card
      class="mt-2"
    >
      <b-card-title class="pl-1 border-left-primary border-left-3 d-inline">
        Conversion History
      </b-card-title>

      <b-row v-if="transactionItems.length <= 0">
        <b-col class="my-2">
          No transactions to show.
        </b-col>
      </b-row>
      <b-row v-if="transactionItems.length > 0">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="perPageSelect"
            >Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          sm="8"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="sortBySelect"
            >Sort</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    Select
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- <b-col
          md="6"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="filterInput"
            >
              Filter
            </label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col> -->

        <b-col cols="12 mt-2">
          <b-table
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="transactionItems"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(created_at)="data">
              {{ getReadableDate(data.item.created_at) }}
            </template>

            <template #cell(value_pennies)="data">
              <b-form-rating
                v-if="data.item.status !== 'won'"
                v-b-popover.hover.top="data.item.currency ? `${data.item.value_pennies * 0.01} ${data.item.currency}` : ''"
                :value="calculateStarRating(data.item.value_pennies, data.item.status)"
                size="sm"
                inline
                no-border
                readonly
              />
              <span v-if="data.item.status === 'won'">
                {{ data.item.value_pennies * 0.01 }} {{ data.item.currency }}
              </span>
            </template>
          </b-table>
        </b-col>
        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>

    <ApproveConversionModal
      ref="conversionModal"
      :selected-conversion="selectedConversion"
      :view-only="viewOnly"
      :return-to="returnTo"
      @refresh-event="refreshConversion"
    />
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard, BCardTitle, BButton,
  BTable, BFormGroup, BFormRating, BFormSelect, BPagination,
  BInputGroup, // BFormInput, BInputGroupAppend,
  BAlert,
  VBPopover,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ApproveConversionModal from '../components/conversion-modal/ConversionModal.vue'

import useJwt from '@/auth/jwt/useJwt'

// tracking parameters from js script
const validTrackingParams = [
  'mobile',
  'gclid',
  'cmpid',
  'fbclid',
  'event_category',
  'event_action',
  'event_label',
  'utm_audience',
  'utm_campaign',
  'utm_campaign_id',
  'utm_campaign_source',
  'utm_campaign_medium',
  'utm_campaign_name',
  'utm_campaign_term',
  'utm_campaign_content',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_source',
  'utm_source_platform',
  'utm_term',
  'conversion_name',
  'nw',
  'agid',
  'fiid',
  'tgid',
  'loc',
  'ploc',
  'match',
  'dev',
  'devm',
  'cmpt',
  'ad',
  'pl',
  'pltar',
  'pr1',
  'pr2',
  'url',
  'adpos',
]

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BTable,
    BFormGroup,
    BFormRating,
    BFormSelect,
    BPagination,
    BInputGroup,
    // BFormInput, BInputGroupAppend,
    BAlert,

    ApproveConversionModal,
  },
  directives: {
    'b-popover': VBPopover,
  },

  data() {
    return {
      resource: {},
      selectedConversion: 0,
      selectedModalAction: 'verify',
      viewOnly: true,
      returnTo: null,

      statusColor: {
        verified: 'success',
        flagged: 'warning',
        spam: 'warning',
        new: 'info',
        rejected: 'danger',
        pending: 'warning',
        lost: 'danger',
        won: 'success',
      },

      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        // { key: 'id', label: 'Id', sortable: true },
        { key: 'user_name', label: 'User', sortable: true },
        { key: 'created_at', label: 'Date Created', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'value_pennies', label: 'Value', sortable: true },
      ],
      transactionItems: [],
      remapFormData: {},
      formIsRemapped: false,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id
    },
    typeIsForm() {
      return (!!this.remapFormData.form_original_data_raw) // TODO: check if conversion is type F
    },
    validUtmData() {
      const validUtmData = {}
      validTrackingParams.forEach(element => {
        if (this.resource && this.resource[element]) {
          validUtmData[element] = this.resource[element]
        }
      })
      return validUtmData
    },
    verifiedStatus() {
      return (this.resource.status === 'verified' || this.resource.status === 'pending')
    },
    wonStatus() {
      return (this.resource.status === 'won')
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // save return to url
    if (this.$route.query && this.$route.query.returnTo) {
      this.returnTo = this.$route.query.returnTo
    }

    const conversionId = this.$route.params.id
    if (conversionId) {
      // load conversion
      this.getConversion(conversionId)
    } else if (!conversionId && this.returnTo) {
      // missing conversion id
      this.$router.push(this.returnTo)
    } else {
      // missing conversion id
      this.$router.push({ name: 'browse-conversions' })
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getReadableDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },

    /* GET CONVERSION DATA */

    refreshConversion() {
      // refresh all conversion data
      const conversionId = this.$route.params.id
      this.getConversion(conversionId)
    },
    getConversion(conversionId) {
      // set conversion id for modal
      this.selectedConversion = parseInt(conversionId, 10)

      // open conversion approval form if approval route
      if (this.$route.name === 'verify-conversion') {
        this.viewOnly = false
      }

      // fetch conversion
      useJwt.getConversion(conversionId)
        .then(response => {
          console.log('getConversion', response.data.data) // DELETE LATER
          const resourceData = response.data.data
          this.resource = resourceData
        })
        .catch(error => {
          console.log(error.response.data)
          this.showErrorMessage(error.response.data.error)
        })

      // fetch conversion transactions
      useJwt.getConversionTransactions(conversionId)
        .then(response => {
          console.log('getConversionTransactions', response.data.data) // DELETE LATER
          const resourceData = response.data.data
          this.transactionItems = resourceData
        })
        .catch(error => {
          console.log(error.response.data)
          this.showErrorMessage(error.response.data.error)
        })

      // fetch form remap menu
      this.getFormRemapMenu(conversionId)
    },
    getFormRemapMenu(conversionId) {
      // fetch conversion remap menu
      useJwt.getRemapMenu(conversionId)
        .then(response => {
          console.log('getRemapMenu', response.data.data) // DELETE LATER
          const resourceData = response.data.data
          this.remapFormData = resourceData

          if (resourceData.form_remapped_data || !this.typeIsForm) {
            this.formIsRemapped = true
          }
        })
        .catch(error => {
          console.log(error.response.data)
          this.showErrorMessage(error.response.data.error)
        })
    },
    calculateStarRating(conversionValuePennies, conversionStatus) {
      return this.$refs.conversionModal ? this.$refs.conversionModal.calculateStarRating(conversionValuePennies, conversionStatus) : 0
    },

    /* REMAP LEAD FORM */

    openFormRemapModal() {
      // open form remap modal
      this.$refs.conversionModal.openFormRemapModal()
    },

    /* APPROVE CONVERSIONS */

    openApproveConversionForm() {
      // open modal approval form regardless of whether or not it's a google conversion
      this.$refs.conversionModal.openApproveConversionForm()
    },
    rejectConversion(conversionId) {
      // open reject conversion prompt
      this.$refs.conversionModal.rejectConversion(conversionId)
    },
    spamConversion(conversionId) {
      // open spam conversion prompt
      this.$refs.conversionModal.spamConversion(conversionId)
    },
    handleItemApprove(conversionId, modalAction) {
      // valid options: approve, lost, pending, won
      this.selectedModalAction = modalAction || 'verify'
      if (conversionId === this.selectedConversion) {
        this.$refs.conversionModal.launchSelectedModal(this.selectedModalAction, conversionId)
      }
    },
    handleItemCooldown(conversionData) {
      // open modal explaining conversion cooldown
      this.$swal({
        title: 'On Cooldown',
        html: `Conversions can't be verified with Google in the first 24 hours. Please try again later.<br/><br/>This conversion is <b>${conversionData.age_in_hours} hour(s) old</b>.`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
    },

    /* MODAL */

    showErrorMessage(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: errorMessage,
        },
      },
      {
        timeout: false,
      })
    },

  },
}
</script>
